import Navigation from "../Navigation";
import {Outlet} from "react-router-dom";

import Footer from "../Footer";

const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
    },
    outletContainer: {
        flexGrow: "1",
        paddingTop: "60px"
    }
}

function Main() {
    return (
        <div  style={styles.mainContainer}>
            <div>
                <Navigation/>
            </div>

            <div style={styles.outletContainer}>
                <Outlet/>
            </div>
            <div>
                <Footer/>
            </div>

        </div>
    )
}

export default Main;