import React from 'react';
import {AppBar, Tabs, Tab, Typography, Box, useMediaQuery, Drawer, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';

import logo from "../../images/logo.jpeg"
import {useTheme} from "@mui/material/styles";

const styles = {
    logo: {
        flexGrow: 1,
        paddingLeft: "10px"
    },
    navigationTabs: {
        flexGrow: 1
    },
    logoImage: {
        borderRadius: "50%",
        height: "90%",
        objectFit: "cover",
        padding: "1em"
    }
}

const getTabIndex = (pathname) => {
    const tabMap = {
        '/': 0,
        '/projects': 1,
        '/blog': 2,
        '/about': 3,
        '/contact': 4,
    };
    return tabMap[pathname] || 0;
};

const Navigation = () => {
    const location = useLocation();
    const [value, setValue] = React.useState(getTabIndex(location.pathname));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (isMobile) setDrawerOpen(false); // Close drawer if it's open on mobile
    };


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };



    const tabs = (
        <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary" orientation={isMobile ? "vertical" : "horizontal"}>
            <Tab label="Home" href="/"/>
            <Tab label="Projects" href="/projects"/>
            <Tab label="Blog" href="/blog"/>
            <Tab label="About" href="/about"/>
            <Tab label="Contact" href="/contact"/>
        </Tabs>
    );

    return (
        <AppBar>
            <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: "60px"}}>
                <img style={styles.logoImage} src={logo} alt="Paul-Louis"/>
                {isMobile && (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                )}
                {!isMobile ? tabs : null}
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box sx={{ width: 250 }}>
                        {tabs}
                    </Box>
                </Drawer>
            </Box>
        </AppBar>
    );
};

export default Navigation;