import React, {useState, useEffect} from 'react';
import Typist from 'react-typist-component';

import cityMorning from "../../images/background/city/morning.png";
import cityDay from "../../images/background/city/day.png";
import cityEvening from "../../images/background/city/evening.png";

import developerMorning from "../../images/background/developer/morning.png"
import developerDay from "../../images/background/developer/day.png"
import developerEvening from "../../images/background/developer/evening.png"

import riverMorning from "../../images/background/river/morning.png"
import riverDay from "../../images/background/river/day.png"
import riverEvening from "../../images/background/river/evening.png"

const morningImages = [cityMorning, developerMorning, riverMorning];
const dayImages = [cityDay, developerDay, riverDay];
const eveningImages = [cityEvening, developerEvening, riverEvening];

const styles = {
    homeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        textAlign: "center"
    },
    myName: {
        fontSize: "4em",
        fontWeight: "bold"
    },
    strokeText: {
        color: "white",
        webkitTextStroke: "2px black",
        textStroke: "2px black"
    }
};

const Home = () => {
    const [backgroundImage, setBackgroundImage] = useState("");
    const [textColor, setTextColor] = useState("black"); // default color

    useEffect(() => {
        const hour = new Date().getHours();

        const randomImageIndex = Math.floor(Math.random() * 3)

        if (hour >= 6 && hour < 18) {
            setBackgroundImage(morningImages[randomImageIndex]);
            setTextColor("white");
        } else if (hour >= 18 && hour < 21) {
            setBackgroundImage(dayImages[randomImageIndex]);
            setTextColor("black");
        } else {
            setBackgroundImage(eveningImages[randomImageIndex]);
            setTextColor("white");
        }


    }, []);

    const containerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (<div style={{...containerStyle, color: textColor, ...styles.homeContainer}}>
            <div style={{...styles.myName, ...styles.strokeText}}>
                <Typist cursor="|" loop={true}>
                    I'm Paul-Louis, a fullStack developer
                    <Typist.Delay ms={5_000}/>
                    <Typist.Backspace count={19}/>
                    mobile app developer
                    <Typist.Delay ms={5_000}/>
                    <Typist.Backspace count={38}/>
                </Typist>
            </div>

        </div>
    );
}

export default Home;
