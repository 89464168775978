import React from 'react';

import ScrollToAnchor from './ScrollToAnchor';

const privacyStyles = {
    privacyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        maxWidth: '800px',
        textAlign: 'center',
    },
    separator: {
        width: '80%',
        margin: '2rem 0',
    },
    body: {
        fontFamily: 'Arial, sans-serif',
        margin: 'auto',
    },
    h2: {
        color: '#333',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    p: {
        marginBottom: '10px',
    },
};

// Add a section about unity : https://unity.com/legal/game-player-and-app-user-privacy-policy
export default function Privacy() {
    return (
        <div style={privacyStyles.privacyContainer}>
            <ScrollToAnchor />
            <div style={privacyStyles.section}>
                <h1>Privacy Policy (English)</h1>
                <h4>Update date: 2nd, January 2024</h4>
                <h2 style={privacyStyles.h2}>Privacy Policy for "Ask to Learn Languages"</h2>

                <h3>Data Collection</h3>
                <p style={privacyStyles.p}>When using our "Ask to Learn Languages" app, we collect the following data:</p>
                <ul>
                    <li>Your email address.</li>
                    <li>Your password (encrypted and secure).</li>
                    <li>The words you wish to learn.</li>
                </ul>

                <h3>Use of Data</h3>
                <p style={privacyStyles.p}>This data is exclusively used to allow you to log in and benefit from the app’s services.</p>

                <h3>Data Storage</h3>
                <p style={privacyStyles.p}>Your data is securely stored on back4app. We utilize all the protections provided by back4app to
                    ensure the security of your information.</p>

                <h3>Data Sharing</h3>
                <p style={privacyStyles.p}>We do not share your data with third parties, with the exception of the Google Play console, the
                    platform from which our app is available for download.</p>

                <h3>Data Retention</h3>
                <p style={privacyStyles.p}>Your data is retained for the period authorized by French law.</p>

                <h3>Data Protection</h3>
                <p style={privacyStyles.p}>We employ back4app’s functionalities to protect your data as best as possible.</p>

                <h3>User Rights</h3>
                <p style={privacyStyles.p}>In accordance with the GDPR, you have the right to access, rectify, or delete your data at any
                    time.</p>

                <h3>Cookies and Similar Technologies</h3>
                <p style={privacyStyles.p}>Our app does not make use of cookies or trackers.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>For any questions or concerns about your privacy, please contact us at the following address:
                    wonderfulappstudio.paul.louis@gmail.com</p>

                <h2 style={privacyStyles.h2}>Privacy Policy for "JDG - Trading Card Game Mobile"</h2>

                <h3>Data Collection</h3>
                <p style={privacyStyles.p}>Our "JDG - Trading Card Game Mobile" app does not collect any personal data from users.</p>

                <h3>Data Storage</h3>
                <p style={privacyStyles.p}>All data is stored locally on your device and relies on protections associated with your device and
                    any protections set up by Unity.</p>

                <h3>Data Sharing</h3>
                <p style={privacyStyles.p}>Unity might access certain unspecified data, but we do not directly share any data.</p>

                <h3>User Rights</h3>
                <p style={privacyStyles.p}>In accordance with the GDPR, you have the right to access, rectify, or delete your data at any
                    time.</p>

                <h3>Cookies and Similar Technologies</h3>
                <p style={privacyStyles.p}>Our app does not make use of cookies or trackers.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>For any questions or concerns about your privacy, please contact us at the following address:
                    wonderfulappstudio.paul.louis@gmail.com</p>


                <h2 id="NotifyMeHere" style={privacyStyles.h2}>Privacy Policy for "Notify me Here!"</h2>
                <h3>Data Collection</h3>
                <p style={privacyStyles.p}>When using "Notify me Here!", we collect the following data:</p>
                <ul>
                    <li>location data to indicate interest points positions</li>
                </ul>

                <h3>Data Storage</h3>
                <p style={privacyStyles.p}>All data is stored locally on your device and relies on protections associated with your device</p>

                <h3>Data Sharing</h3>
                <p style={privacyStyles.p}>As all data are stored on your device, we don't have any access on it.</p>

                <h3>User Rights</h3>
                <p style={privacyStyles.p}>In accordance with the GDPR, you have the right to access, rectify, or delete your data at any
                    time.</p>

                <h3>Cookies and Similar Technologies</h3>
                <p style={privacyStyles.p}>Our app does not make use of cookies or trackers.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>For any questions or concerns about your privacy, please contact us at the following address:
                    wonderfulappstudio.paul.louis@gmail.com</p>

            </div>

            <hr style={privacyStyles.separator}/>

            <div style={privacyStyles.section}>
                <h1>Politique de Confidentialité (Français)</h1>
                <h4>Date de mise à jour: 2 janvier 2024</h4>
                <h2 style={privacyStyles.h2}>Règles de confidentialité pour "Ask to Learn Languages"</h2>

                <h3>Données collectées</h3>
                <p style={privacyStyles.p}>Lors de l'utilisation de notre application "Ask to Learn Languages", nous collectons les données
                    suivantes :</p>
                <ul>
                    <li>Votre adresse e-mail.</li>
                    <li>Votre mot de passe (crypté et sécurisé).</li>
                    <li>Les mots que vous souhaitez apprendre.</li>
                </ul>

                <h3>Utilisation des données</h3>
                <p style={privacyStyles.p}>Ces données sont exclusivement utilisées pour vous permettre de vous connecter et de profiter des
                    services de l'application.</p>

                <h3>Stockage des données</h3>
                <p style={privacyStyles.p}>Vos données sont stockées en toute sécurité sur back4app. Nous utilisons toutes les protections
                    fournies par back4app pour garantir la sécurité de vos informations.</p>

                <h3>Partage des données</h3>
                <p style={privacyStyles.p}>Nous ne partageons pas vos données avec des tiers, à l'exception de Google Play console, qui est la
                    plateforme à partir de laquelle notre application est disponible au téléchargement.</p>

                <h3>Conservation des données</h3>
                <p style={privacyStyles.p}>Vos données sont conservées pendant la durée autorisée par la loi française.</p>

                <h3>Protection des données</h3>
                <p style={privacyStyles.p}>Nous utilisons les fonctionnalités de back4app pour protéger vos données dans la mesure du
                    possible.</p>

                <h3>Droits de l'utilisateur</h3>
                <p style={privacyStyles.p}>Conformément au RGPD, vous avez le droit d'accéder, de rectifier ou de supprimer vos données à tout
                    moment.</p>

                <h3>Cookies et technologies similaires</h3>
                <p style={privacyStyles.p}>Notre application ne fait pas usage de cookies ou de traceurs.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>Pour toute question ou préoccupation concernant votre vie privée, veuillez nous contacter à l'adresse
                    suivante : wonderfulappstudio.paul.louis@gmail.com</p>

                <h2 style={privacyStyles.h2}>Règles de confidentialité pour "JDG - Trading Card Game Mobile"</h2>

                <h3>Données collectées</h3>
                <p style={privacyStyles.p}>Notre application "JDG - Trading Card Game Mobile" ne collecte aucune donnée personnelle des
                    utilisateurs.</p>

                <h3>Stockage des données</h3>
                <p style={privacyStyles.p}>Toutes les données sont stockées localement sur votre appareil et dépendent des protections associées
                    à votre appareil et des éventuelles protections mises en place par Unity.</p>

                <h3>Partage des données</h3>
                <p style={privacyStyles.p}>Unity pourrait avoir accès à certaines données non spécifiées, mais nous ne partageons aucune donnée
                    directement.</p>

                <h3>Droits de l'utilisateur</h3>
                <p style={privacyStyles.p}>Conformément au RGPD, vous avez le droit d'accéder, de rectifier ou de supprimer vos données à tout
                    moment.</p>

                <h3>Cookies et technologies similaires</h3>
                <p style={privacyStyles.p}>Notre application ne fait pas usage de cookies ou de traceurs.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>Pour toute question ou préoccupation concernant votre vie privée, veuillez nous contacter à l'adresse
                    suivante : wonderfulappstudio.paul.louis@gmail.com</p>

                <h2 id="NotifyMeHereFR" style={privacyStyles.h2}>Règles de confidentialité pour "Notify me Here!"</h2>

                <h3>Data Collection</h3>
                <p style={privacyStyles.p}>Lors de l'utilisation de notre application "Notify me Here!", nous collectons les données
                    suivantes :</p>
                <ul>
                    <li>les données de localisation pour indiquer les positions des points d'intérêt</li>
                </ul>

                <h3>Data Storage</h3>
                <p style={privacyStyles.p}>Toutes les données sont stockées localement sur votre appareil et dépendent des protections associées
                    à votre appareil.</p>

                <h3>Data Sharing</h3>
                <p style={privacyStyles.p}>Toutes les données sont stockées sur votre appareil donc nous n'y avons pas accès.</p>

                <h3>Droits de l'utilisateur</h3>
                <p style={privacyStyles.p}>Conformément au RGPD, vous avez le droit d'accéder, de rectifier ou de supprimer vos données à tout
                    moment.</p>

                <h3>Cookies et technologies similaires</h3>
                <p style={privacyStyles.p}>Notre application ne fait pas usage de cookies ou de traceurs.</p>

                <h3>Contact</h3>
                <p style={privacyStyles.p}>Pour toute question ou préoccupation concernant votre vie privée, veuillez nous contacter à l'adresse
                    suivante : wonderfulappstudio.paul.louis@gmail.com</p>
            </div>
        </div>
    );
}