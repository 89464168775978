import {getAge} from "./aboutManager";
import {Box} from "@mui/material";

function About() {
    const age = getAge(new Date(856_220_400_000));
    return (<div style={{textAlign: "center"}}>
        <p>
            I'm Paul-Louis Renard, {age} years old, a software engineer working on Node.js servers, Android and iOS
            platform.
        </p>
        <Box>
            <h2>Study</h2>
            <p>After high school, I followed higher school preparatory classes (Scientific section) to go after
                two years into an engineering school after a competitive entrance examination. This experience helped me
                work on my communication skills thanks to numerous khôles (oral examinations in front of a blackboard
                in which you have to resolve scientific exercises or talk in English).</p>
            <p>This leads me to continue my scholarship in ENSSAT(École Nationale Supérieure des Sciences Appliquées et
                de Technologie), Lannion, France. In this engineering school, I learned about computer science in 3
                years. I spent my last year abroad as a foreign student at Laval University. At this University, I
                studied video games, AI, and mobile development. That leads me to represent Laval University in the
                Ubisoft Game Competition in Québec.</p>
            <h2>Work experience</h2>
            <p>

            </p>
        </Box>
        <h2></h2>
    </div>)
}

export default About;