import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";



function BasicCard() {
    return (
        <Card sx={{ maxWidth: "50%"}}>
            <CardContent>
                <Typography variant="h2" component="div">
                    Contact
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Send</Button>
            </CardActions>
        </Card>
    );
}

function Contact() {
    return (
        <Box sx={{ display:"flex", justifyContent: "center"}}>
            <BasicCard/>
        </Box>

    )
}

export default Contact;