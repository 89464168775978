import { useEffect } from 'react';

function ScrollToAnchor() {
    useEffect(() => {
        if (window.location.hash) {
            const targetElement = document.querySelector(window.location.hash);
            if (targetElement) {
                targetElement.scrollIntoView();
            }
        }
    }, []);

    return null; // This component doesn't render anything
}

export default ScrollToAnchor;
