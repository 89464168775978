import React from "react";

import {useTheme} from "@mui/material/styles";

const styles = {
    footer: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "1em",
        flexDirection: "column",
        alignItems: "center"
    },
    footerImages: {
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        marginBottom: "1rem"
    },
    image: {
        height: "40px",
        width: "40px",
        margin: "0 10px"
    }
}

function Footer() {
    const theme = useTheme()

    const currentYear = new Date().getFullYear();

    const footerStyle = {
        backgroundColor: theme.palette.primary.main,
        ...styles.footer
    }

    return (<footer style={footerStyle}>
        <div style={styles.footerImages}>
            <a href="https://stackoverflow.com/users/8067131/paul"><img
                style={styles.image}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Stack_Overflow_icon.svg/1200px-Stack_Overflow_icon.svg.png"
                alt="StackOverflow"/></a>
            <a href="https://www.linkedin.com/in/paul-louisrenard"><img
                style={styles.image}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png"
                alt="LinkedIn"/></a>
            <a href="https://github.com/PLR2388"><img
                style={styles.image}
                src="https://cdn.icon-icons.com/icons2/2428/PNG/512/github_black_logo_icon_147128.png"
                alt="Github"/></a>
        </div>
        <div>
            &copy; Paul-Louis.fr {currentYear}
        </div>
    </footer>)
}

export default Footer;