import Box from "@mui/material/Box";
import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/material/Divider';

const ProjectTitle = ({title, technologies}) => (
    <Stack marginBottom="10px" direction="row" alignItems="center" spacing={2} width="50%">
        <h3>{title}</h3>
        {technologies.map((name, index) => (
            <img
                width="20px"
                key={index}
                src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
                alt={name}
            />
        ))}
    </Stack>
);

const ProjectPresentation = ({title, technologies, image}) => (
    <Stack sx={{m: 2}}>
        <ProjectTitle title={title} technologies={technologies}/>
        <img height="50%" src={image} alt="image"/>
    </Stack>
);

const ProjectRightBlock = () => (
    <Stack>
        <Divider/>
    </Stack>
)

function Projects() {
   /* return (
        <div style={{width: '100%', textAlign: "center"}}>

            <Box sx={{display: 'grid', gridAutoColumns: "1fr"}}>
                <ProjectPresentation title={"My project"} technologies={["android", "kotlin"]}
                                     image={"https://upload.wikimedia.org/wikipedia/commons/5/53/Shadow_2752.jpg"}/>
                <ProjectPresentation title={"My project"} technologies={["android", "kotlin"]}
                                     image={"https://upload.wikimedia.org/wikipedia/commons/5/53/Shadow_2752.jpg"}/>
            </Box>

        </div>
    );*/
}

export default Projects;